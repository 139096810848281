import * as React from 'react'
import { graphql } from 'gatsby'

import { Slots } from '../sections/slots'
import { PageLayout } from '../components/page-layout'

export interface IBaseProps {
  data: any
}

const IndexPage = ({ data }: IBaseProps) => {
  const { contentfulHomePage, allContentfulPage, allFooterNavigation, contentfulSiteSettings } = data
  const footerNavItems = allFooterNavigation.nodes
  const { bottomHero, hero, title, slots } = contentfulHomePage
  const { metaTitlePrefix, headerCta } = contentfulSiteSettings

  return (
    <PageLayout
      navItems={allContentfulPage.nodes}
      footerNavItems={footerNavItems}
      metaTitlePrefix={metaTitlePrefix}
      headerCta={headerCta}
      title={title}
      hero={hero}
      bottomHero={bottomHero}
    >
      <Slots slots={slots} />
    </PageLayout>
  )
}

export default IndexPage

export const query = graphql`
  query HomePage {
    contentfulSiteSettings {
      headerCta {
        ...LinkContent
      }
      metaTitlePrefix
    }
    contentfulHomePage {
      title
      bottomHero {
        ...HeroContent
      }
      hero {
        ...HeroContent
      }
      slots {
        ... on ContentfulCardContainer {
          ...CardContainerContent
        }
        ... on ContentfulArticleContainer {
          ...ArticleContainerContent
        }
      }
    }

    allContentfulPage {
      nodes {
        ...PageContent
      }
    }

    allFooterNavigation {
      nodes {
        ...FooterNavigationContent
      }
    }
  }
`

